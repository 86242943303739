import { css, Theme } from '@emotion/react';

import { weight } from '../../styles';
import {
  breaks,
  colors,
  spacing,
  transitionDuration,
} from '../../styles/tokens';
import hexToRgba from '../../utils/hexToRgba';

type StyleOptions = {
  block?: boolean;
  disabled?: boolean;
  hasImage?: boolean;
  theme?: Theme;
};

const highlightColor = colors.orange700;

export default ({
  block = false,
  disabled = false,
  hasImage = false,
  theme,
}: StyleOptions = {}) => ({
  base: [
    css`
      appearance: none;
      background-color: ${colors.white};
      border: 0;
      box-shadow: 0px 3px 4px ${hexToRgba(colors.grey800, 0.12)};
      display: inline-flex;
      font: inherit;
      ${weight.bold};
      letter-spacing: 0.01em;
      margin: 0;
      padding: 0;
      position: relative;
      text-align: left;
      text-decoration: none;
      transition-property: background-color, box-shadow, color;
      width: 100%;
      -webkit-tap-highlight-color: ${hexToRgba(highlightColor, 0.1)};

      &,
      &[href^='tel:'] {
        color: ${colors.grey800};
      }

      &,
      &::after {
        border-radius: ${spacing.xxs};
        transition-duration: ${transitionDuration};
      }

      &::after {
        border: 2px solid ${colors.grey700};
        bottom: 0;
        content: '';
        display: block;
        left: 0;
        position: absolute;
        right: 0;
        top: 0;
        transition-property: border-color, box-shadow;
      }
    `,
    disabled
      ? css`
          background-color: ${colors.grey200};
          color: ${colors.grey700};

          &,
          &[href^='tel:'] {
            color: ${colors.grey700};
          }

          &::after {
            border-color: ${colors.grey500};
          }
        `
      : css`
          cursor: pointer;

          &:focus,
          &:hover {
            box-shadow: 0px 3px 6px ${hexToRgba(highlightColor, 0.18)};
            outline: 0;
          }

          &:focus::after,
          &:hover::after {
            border-color: ${highlightColor};
          }
        `,
    !block &&
      css`
        @media ${breaks.midPageWidth} {
          min-width: 18rem;
          width: auto;
        }
      `,
  ],
  themed: [
    css`
      background-color: ${theme?.cta?.background};
      ${theme?.cta?.fontWeight};
      &,
      &[href^='tel:'] {
        color: ${theme?.cta?.text};
      }
      &::after {
        border: 2px solid ${theme?.cta?.border};
      }
    `,
    disabled
      ? css`
          background-color: ${colors.grey200};
          color: ${colors.grey700};
          &,
          &[href^='tel:'] {
            color: ${colors.grey700};
          }

          &::after {
            border-color: ${colors.grey500};
          }
        `
      : css`
          background-color: ${theme?.cta?.background};
          &,
          &[href^='tel:'] {
            &:hover {
              color: ${theme?.cta?.text};
            }
          }
          &:focus::after,
          &:hover::after {
            border-color: ${theme?.cta?.border};
          }
        `,
  ],
  outer: [
    css`
      width: 100%;
    `,
    hasImage &&
      css`
        display: grid;
        grid-template-columns: 6.5rem 1fr;
        grid-template-rows: 6.5rem;
        height: 100%;

        @media ${breaks.midPageWidth} {
          grid-template-columns: none;
          grid-template-rows: auto 1fr;
        }
      `,
  ],
  inner: [
    css`
      align-items: center;
      display: grid;
      grid-gap: ${spacing.xs};
      grid-template-columns: 1fr auto;
      min-height: 4.5rem;
      padding: 0.7rem ${spacing.xs} 0.75rem 0.75rem;
    `,
    hasImage &&
      css`
        @media ${breaks.midPageWidth} {
          min-height: auto;
        }
      `,
  ],
  image: css`
    background-color: ${colors.grey200};
    background-position: 50% 50%;
    background-repeat: no-repeat;
    background-size: auto 100%;
    display: grid;

    @media ${breaks.midPageWidth} {
      height: 7.5rem;
    }
  `,
  secondaryText: css`
    display: block;
  `,
  icon: [
    css`
      color: ${highlightColor};
      display: block;
      height: ${spacing.m};
      width: ${spacing.m};
    `,
    disabled &&
      css`
        color: inherit;
      `,
  ],
});
