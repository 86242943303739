/** @jsxImportSource @emotion/react */
import ReactMarkdown from 'react-markdown';

import Link from './Link';
import * as styles from './styles';

const customComponents = { a: Link };

export default function Markdown({ source, ...props }: { source?: string }) {
  return source ? (
    <ReactMarkdown
      css={styles.markdown}
      components={customComponents}
      {...props}
    >
      {source}
    </ReactMarkdown>
  ) : null;
}
