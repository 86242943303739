import { css } from '@emotion/react';

import { colors, spacing } from '../../styles/tokens';

export const base = css`
  background-color: ${colors.orange500};
  display: block;
  height: 2px;
  margin: ${spacing.s} 0;
  width: ${spacing.m};
`;
