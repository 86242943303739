/** @jsxImportSource @emotion/react */
import { AnchorHTMLAttributes } from 'react';
import { useTranslation } from 'react-i18next';

import { visuallyHidden } from '../../styles';

type Props = AnchorHTMLAttributes<HTMLAnchorElement>;
type Additional = {
  ariaLabel?: string;
};

export default function ExternalLink({
  children,
  href,
  role,
  ariaLabel,
  ...props
}: Props & Additional) {
  const [common] = useTranslation('common');

  return (
    <a
      target="_blank"
      rel="noopener noreferrer"
      role={role}
      aria-label={ariaLabel || href}
      href={href}
      {...props}
    >
      {children}
      <span css={visuallyHidden}> - {common('externalLink')}</span>
    </a>
  );
}
