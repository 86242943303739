/** @jsxImportSource @emotion/react */
import { ButtonHTMLAttributes, ReactNode } from 'react';

import { TestIdProp } from '../../types';
import useStyles, { IconAlignment } from './useStyles';

type Props = ButtonHTMLAttributes<HTMLButtonElement> &
  Required<TestIdProp> & {
    icon?: ReactNode;
    iconAlignment?: IconAlignment;
    iconSmall?: boolean;
  };

export { IconAlignment };

export default function LinkButton({
  children,
  icon,
  iconAlignment = IconAlignment.Right,
  iconSmall = false,
  testId,
  ...props
}: Props) {
  const styles = useStyles({
    direction: iconAlignment,
    iconSmall,
    hasIcon: !!icon,
  });

  return (
    <button css={styles.base} data-test-id={testId} type="button" {...props}>
      <div css={styles.inner}>
        {icon && iconAlignment === IconAlignment.Left && (
          <div css={styles.icon}>{icon}</div>
        )}
        {children}
        {icon && iconAlignment === IconAlignment.Right && (
          <div css={styles.icon}>{icon}</div>
        )}
      </div>
    </button>
  );
}
