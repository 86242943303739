import { useCampaign } from '../campaign';
import { useGoogleOptimizeEffect } from '../googleOptimize';
import useInfinityTracking from '../useInfinityTracking';
import useMatelsoTracking from '../useMatelsoTracking';
import usePageTitle from '../usePageTitle';
import useResetScroll from '../useResetScroll';

export default function usePage(title?: string) {
  useResetScroll();

  usePageTitle(title);

  useGoogleOptimizeEffect();

  useInfinityTracking();

  useMatelsoTracking();

  useCampaign();
}
