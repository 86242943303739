import { css } from '@emotion/react';

import { heading } from '../../styles';
import { spacing } from '../../styles/tokens';

export const title = css`
  ${heading.size2};
  margin-top: ${spacing.l};
  @media print {
    margin-top: 0;
    padding-left: ${spacing.s};
  }
`;
