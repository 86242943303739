/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import { PageId } from '../../consts';
import { heading, mb, text } from '../../styles';
import { AppErrorData } from '../../types';
import usePage from '../../utils/usePage';
import Boxout from '../Boxout';
import Cta from '../Cta';
import CustomFreephone from '../CustomFreephone';
import Divider from '../Divider';
import { useErrorBoundary } from '../ErrorBoundary/context';
import Markdown from '../Markdown';
import Page from '../Page';
import PageContent from '../PageContent';
import PageHeader from '../PageHeader';
import PhoneNumberCta from '../PhoneNumberCta';
import * as styles from './styles';

type Props = AppErrorData;

export default function ErrorPage({
  action,
  message,
  name,
  onRetry,
  refresh,
}: Props) {
  const errorBoundary = useErrorBoundary();
  const [errors] = useTranslation('errors');
  const [config] = useTranslation('config');
  const history = useHistory();
  const customFreephone = config('features.customFreephone');

  usePage(name);

  const handleRetry = () => {
    if (onRetry) {
      // If we are inside an error boundary, reset it
      if (errorBoundary) {
        errorBoundary.reset();
      }

      onRetry();
    }
    if (refresh) {
      history.go(0);
    }
  };

  return (
    <Page id={PageId.Error}>
      <PageHeader heading={name}>
        <Divider />
      </PageHeader>
      <PageContent css={styles.content}>
        <div>
          <Markdown source={message} css={mb.s} />
          {action && onRetry && (
            <p>
              <Cta
                primaryText={action}
                onClick={handleRetry}
                testId="cta-retry"
              />
            </p>
          )}
        </div>
        <Boxout css={styles.boxout}>
          <div css={text.size5}>
            <Markdown source={errors('seeingAProblem')} />
          </div>
          {customFreephone ? (
            <CustomFreephone boxed={true} testId="phoneNumberCta-error" />
          ) : (
            <p>
              <span css={heading.size4}>
                <PhoneNumberCta testId="phoneNumberCta-error" />
              </span>
            </p>
          )}
        </Boxout>
      </PageContent>
    </Page>
  );
}
