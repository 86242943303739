import { useEffect, useState } from 'react';

import { AnalyticsPageId, AnalyticsStepLegacy } from '../../consts';
import { PaymentResponsePayload, ProductConditionBreakdown } from '../../types';
import createProduct, { ProductDetails } from './createProduct';

export const track = (data: Record<string, unknown>) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(data);

  if (process.env.NODE_ENV === 'development' && 'console' in window) {
    /* eslint-disable no-console */
    console.groupCollapsed('[Tracking] Push');
    Object.entries(data).forEach(([key, value]) => {
      console.log(`${key}:`, value);
    });
    console.groupEnd();
    /* eslint-enable no-console */
  }
};

export const useTrackPage = (data?: {
  currentStep?: AnalyticsStepLegacy;
  id: AnalyticsPageId;
  name: string;
}) => {
  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    if (!data || isTracked) return;

    setIsTracked(true);

    const { currentStep, id, name } = data;

    track({
      event: 'configuratorStepLoaded',
      page: {
        ...(currentStep ? { currentStep } : null),
        id,
        name,
      },
    });
  }, [data, isTracked]);
};

type CheckoutEvent = {
  data?: ProductDetails;
  condition?: ProductConditionBreakdown;
  step: number;
};

export const trackCheckout = ({ data, condition, step }: CheckoutEvent) => {
  track({
    event: `checkoutStep${step}`,
    ecommerce: {
      checkout: {
        actionField: {
          step,
        },
        ...(data
          ? {
              products: [
                createProduct({
                  ...data,
                  productCondition: condition?.productCondition,
                  pricing: condition?.pricing,
                }),
              ],
            }
          : null),
      },
    },
  });
};

type PurchaseEvent = {
  currencyCode: string;
  data: PaymentResponsePayload;
  id: string;
  revenue: string;
  shipping: string;
};

export const trackPurchase = ({
  currencyCode,
  data,
  id,
  revenue,
  shipping,
}: PurchaseEvent) => {
  track({
    event: 'paymentConfirmation',
    ecommerce: {
      currencyCode,
      purchase: {
        actionField: {
          id,
          affiliation: 'Online Configurator',
          revenue,
          shipping,
        },
        products: [
          createProduct({
            ...data.details,
            productCondition: data.details.productCondition,
            pricing: data.details.pricing,
          }),
        ],
      },
    },
  });
};

export const useTrackCheckout = (data?: CheckoutEvent) => {
  const [isTracked, setIsTracked] = useState(false);

  useEffect(() => {
    if (!data || isTracked) return;

    setIsTracked(true);

    trackCheckout(data);
  }, [data, isTracked]);
};
