import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { GoInStore, MarketingCampaign, SessionKey } from '../../consts';
import { putMarketingCampaign } from '../../services/backend';

const campaignEventListener = (campaign: MarketingCampaign, event: string) => {
  window.addEventListener(event, () => {
    sessionStorage.setItem(SessionKey.Campaign, campaign);
  });
};

export function useCampaign() {
  const [config] = useTranslation('config');
  const hasGoInStore = config('features.hasGoInStore');

  useEffect(() => {
    if (hasGoInStore)
      campaignEventListener(
        MarketingCampaign.GoInstore,
        GoInStore.evenListener
      );
  }, [hasGoInStore]);
}

export function isGoInstoreCampaign() {
  return (
    sessionStorage.getItem(SessionKey.Campaign) === MarketingCampaign.GoInstore
  );
}

export async function setMarketingCampaign() {
  return isGoInstoreCampaign()
    ? putMarketingCampaign({
        marketingCampaign: MarketingCampaign.GoInstore,
      })
    : Promise.resolve();
}
