/** @jsxImportSource @emotion/react */
import { ThemeProvider } from '@emotion/react';
import { ReactNode, StrictMode, Suspense } from 'react';

import { themeConfig } from '../../styles/themeConfig';
import { AppContextProvider } from '../App/context';
import GlobalStyles from '../GlobalStyles';

type Props = { children: ReactNode };

const { marketId } = window.__config;

export default function Providers({ children }: Props) {
  return (
    <StrictMode>
      <Suspense fallback={null}>
        <ThemeProvider theme={themeConfig[marketId]}>
          <AppContextProvider>
            <GlobalStyles />
            {children}
          </AppContextProvider>
        </ThemeProvider>
      </Suspense>
    </StrictMode>
  );
}
