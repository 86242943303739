/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';

import { TestIdProp } from '../../types';
import useInfinityTracking from '../../utils/useInfinityTracking';
import useMatelsoTracking from '../../utils/useMatelsoTracking';

type Props = TestIdProp;

export default function PhoneNumberCta({ testId }: Props) {
  const [common] = useTranslation('common');

  useInfinityTracking();

  useMatelsoTracking();

  return (
    <a
      data-test-id={testId}
      className="InfinityNumber"
      title={common('freephone')}
      href={`tel:${common('phoneNumber.raw')}`}
    >
      {common('phoneNumber.formatted')}
    </a>
  );
}
