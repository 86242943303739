/** @jsxImportSource @emotion/react */
import { ImgHTMLAttributes } from 'react';

import { TestIdProp } from '../../types';
import imagePath from '../../utils/imagePath';

type Props = ImgHTMLAttributes<HTMLImageElement> & TestIdProp & { src: string };

export default function Img({ alt = '', src, testId, ...props }: Props) {
  return (
    <img alt={alt} data-test-id={testId} src={imagePath(src)} {...props} />
  );
}
