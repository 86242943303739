/** @jsxImportSource @emotion/react */
import '../../utils/i18n';

import { Fragment, lazy, Suspense, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { BrowserRouter, Route, Switch } from 'react-router-dom';

import useMeta from '../../utils/useMeta';
import ErrorBoundary from '../ErrorBoundary';
import Header from '../Header';
import Loading from '../Loading';
import * as styles from './styles';

const Footer = lazy(() => import('../Footer'));
const NotFoundPage = lazy(() => import('../../pages/errors/NotFoundPage'));

const urlKeyToComponent: Record<string, ReturnType<typeof lazy>> = {
  config: lazy(() => import('../ConfigPage')),
  home: lazy(() => import('../../pages/HomePage')),
  payment: lazy(() => import('../../pages/PaymentPage')),
  paymentCancelled: lazy(() => import('../../pages/PaymentCancelledPage')),
  confirmation: lazy(() => import('../../pages/ConfirmationPage')),
  details: lazy(() => import('../../pages/DetailsPage')),
  personalDetails: lazy(() => import('../../pages/PersonalDetailsPage')),
  installationAddress: lazy(
    () => import('../../pages/InstallationAddressPage')
  ),
  scheduleSurvey: lazy(() => import('../../pages/ScheduleSurveyPage')),
  prerequisites: lazy(() => import('../Prerequisites')),
  contract: lazy(() => import('../../pages/ContractPage')),
  lead: lazy(() => import('../../pages/LeadPage')),
};

const urlsKeysToPartiallyMatch = ['prerequisites'];

const isNotGroup = (
  config: [string, string | Record<string, string> | null]
): config is [string, string] => typeof config[1] === 'string';

export default function App() {
  const [common] = useTranslation('common');
  const [config] = useTranslation('config');

  useMeta({ name: 'description', content: common('appDescription') });

  const routes = useMemo(() => {
    const urls = config('urls');

    return Object.entries(urls)
      .filter(isNotGroup)
      .map(([key, url]) => {
        const Page = urlKeyToComponent[key];
        const path = key === 'config' ? `${url}/:step?` : url;

        return (
          <Route
            exact={!urlsKeysToPartiallyMatch.includes(key)}
            key={url}
            path={path}
          >
            <Page />
          </Route>
        );
      });
  }, [config]);

  return (
    <Fragment>
      <a role="button" href="#content" css={styles.skipLink}>
        {common('skipToContent')}
      </a>
      <BrowserRouter>
        <Header />
        <main css={styles.content} id="content" aria-label={`main`}>
          <ErrorBoundary>
            <Suspense fallback={<Loading overlay />}>
              <Switch>
                {routes}
                <Route path="*">
                  <NotFoundPage />
                </Route>
              </Switch>
            </Suspense>
          </ErrorBoundary>
        </main>
        <Suspense fallback={<Loading overlay />}>
          <Footer />
        </Suspense>
      </BrowserRouter>
    </Fragment>
  );
}
