import { Environment } from '../../consts';

export default function getEnv() {
  const domain = window.location.hostname;
  const zoneDomain = ['stannah.zone.agency', 'e-commerce.stannah.co.uk'];

  if (
    domain.endsWith(`-dev.${zoneDomain[0]}`) ||
    domain.endsWith(`-dev.${zoneDomain[1]}`)
  )
    return Environment.Dev;
  if (
    domain.endsWith(`-test.${zoneDomain[0]}`) ||
    domain.endsWith(`-test.${zoneDomain[1]}`)
  )
    return Environment.Test;
  if (
    domain.endsWith(`-staging.${zoneDomain[0]}`) ||
    domain.endsWith(`-staging.${zoneDomain[1]}`)
  )
    return Environment.Staging;
  if (
    domain.endsWith(`-live.${zoneDomain[0]}`) ||
    domain.endsWith(`-live.${zoneDomain[1]}`) ||
    [
      'my.stannah.co.uk',
      'shop.stannah-stairlifts.com',
      'meu.stannah.pt',
      'mi.stannah.es',
      'mon.stannah.fr',
      'crea.stannah.it',
    ].includes(domain)
  )
    return Environment.Live;

  return Environment.Unknown;
}
