import { css } from '@emotion/react';

import { heading, hiddenOnPrint } from '../../styles';
import { breaks, colors, spacing } from '../../styles/tokens';
import fluidStyles from '../../utils/fluidStyles';

const fluidFontSize = fluidStyles(
  ['19px', '36px'],
  value => `font-size: ${value};`
);

export const header = css`
  ${hiddenOnPrint}
`;

export const inner = css`
  align-items: center;
  border-bottom: 1px solid ${colors.grey300};
  display: flex;
  justify-content: space-between;
  padding: ${spacing.s} 0;
`;

export const logo = css`
  ${fluidFontSize};
  line-height: 1;
`;

export const contact = css`
  align-content: center;
  align-items: center;
  display: grid;
  gap: ${spacing.s};
  justify-content: center;
  justify-items: center;
  height: 100%;
  text-align: center;

  @media ${breaks.midPageWidth} {
    align-items: end;
    gap: ${spacing.xs};
    grid-auto-flow: column;
    justify-items: end;
    height: auto;
    text-align: right;
  }

  ${hiddenOnPrint};
`;
export const contactContent = css`
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 0;
  justify-content: end;
  grid-template-areas:
    'oneCallAway'
    'freephone'
    'phoneNumberCta';

  @media ${breaks.midPageWidth} {
    grid-template-rows: repeat(2, 1fr);
    grid-template-areas:
      'freephone freephone'
      'oneCallAway phoneNumberCta';
  }
`;
export const freephone = css`
  grid-area: freephone;
`;
export const oneCallAway = css`
  grid-area: oneCallAway;
  ${heading.size4};
  padding-bottom: ${spacing.s};
  @media ${breaks.midPageWidth} {
    padding-bottom: 0;
  }
`;

export const phoneNumberCta = css`
  ${heading.size3};
  grid-area: phoneNumberCta;
  @media ${breaks.midPageWidth} {
    padding-left: ${spacing.xs};
  }
`;
export const promise = css`
  width: 88px;
`;
