import {
  ProductCondition,
  RailType,
  ScheduleType,
  SeatSize,
  SeatType,
} from '../../consts';
import { AnswersData, Pricing } from '../../types';

const separator = '-';
const yesOrNo = (target?: boolean) => {
  if (typeof target === undefined) {
    return undefined;
  }

  return target ? 'Yes' : 'No';
};
const firstItem = <T>(target?: T[]) => target && target[0];
const defaultToEmpty = <T>(target?: T) => target ?? '';

export type ProductDetails = {
  content: {
    model: SeatType;
    name: string;
    rail: RailType;
    size: SeatSize;
  };
  answersData: AnswersData;
  pricing?: Pricing;
  productCondition?: ProductCondition;
};

type Product = {
  /** Package name */
  name: string;
  category: 'Stairlift';
  /** Rail type */
  brand: RailType;
  /** Obstruction, in quotes separated by ' - ' */
  variant: string;
  price: string;
  quantity: '1';
  /** Plug socket */
  dimensionN: 'Yes' | 'No' | '';
  /** Rail colour */
  'dimensionN+1': string;
  /** Upholstery - e.g. straight-siena-flc-walnut */
  'dimensionN+2': string;
  /** Easy turn */
  'dimensionN+3': 'Yes' | 'No' | '';
  /** Schedule type */
  'dimensionN+4': string | ScheduleType.AsSoonAsPossible;
  /** First scheduled date */
  'dimensionN+5': string;
  /** Number of turns  */
  'dimensionN+6': string;
};

export default ({
  content,
  answersData,
  pricing,
  productCondition,
}: ProductDetails): Product => ({
  name: `${productCondition ?? ''} ${content.name}`.trim(),
  category: 'Stairlift',
  brand: content.rail,
  variant:
    answersData.obstructions
      ?.map(obstruction => `"${obstruction}"`)
      .join(separator) ?? '',
  price: pricing?.installationCost.total.toString() ?? '0',
  quantity: '1',
  dimensionN: defaultToEmpty(yesOrNo(firstItem(answersData.plugSocket))),
  'dimensionN+1': defaultToEmpty(firstItem(answersData.railColour)),
  'dimensionN+2': [
    content.rail,
    content.model,
    answersData.fabric,
    answersData.trim,
  ]
    .filter(i => !!i)
    .join(separator),
  'dimensionN+3': defaultToEmpty(yesOrNo(firstItem(answersData.easyTurn))),
  'dimensionN+4': defaultToEmpty(firstItem(answersData.scheduleType)),
  'dimensionN+5': defaultToEmpty(firstItem(answersData.firstScheduledDate)),
  'dimensionN+6': defaultToEmpty(firstItem(answersData.numberOfTurns)),
});
