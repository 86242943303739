/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import * as styles from './styles';

type Props = { children: ReactNode };

export default function Boxout({ children, ...props }: Props) {
  return (
    <div css={styles.base} {...props}>
      {children}
    </div>
  );
}
