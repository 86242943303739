import { MinMax } from '../types';

const maxPageWidth = 1200;
const midPageWidth = 720;
const minPageWidth = 360;
const maxFieldWidth = '26.5rem';

export const colors = {
  white: '#fff',
  red500: '#b50000',
  grey100: '#faf9f7',
  grey200: '#f5f3ef',
  grey300: '#cccac7',
  grey500: '#9c9696',
  grey700: '#5c5555',
  grey800: '#333',
  blue200: '#edf8fc',
  blue700: '#006695',
  green200: '#fbfdf4',
  green300: '#f2f9dd',
  green500: '#9abc00',
  green700: '#85a202',
  green900: '#006D4C',
  yellow200: '#fef7e0',
  yellow300: '#ffedb1',
  yellow500: '#e4b720',
  orange200: '#fef5ed',
  orange300: '#ffebd9',
  orange500: '#f0ab00',
  orange700: '#eb7c0f',
  purple200: '#f8f4f9',
  purple300: '#f3e3f6',
  purple500: '#793e74',
  purple700: '#661376',
  gold200: '#f9f4e9',
  gold500: '#9a6e0B',
  customFreephoneGreen: '#72BF44',
};

export const breaks = {
  maxPageWidth: `screen and (min-width: ${maxPageWidth}px)`,
  midPageWidth: `screen and (min-width: ${midPageWidth}px)`,
  minPageWidth: `screen and (min-width: ${minPageWidth}px)`,
};

export const layout = {
  maxPageWidth,
  midPageWidth,
  minPageWidth,
  maxFieldWidth,
};

export const pageGridGap: MinMax = ['18px', '60px'];

export const spacing = {
  xxs: '0.3rem',
  xs: '0.5rem',
  s: '1rem',
  m: '1.5rem',
  l: '2rem',
  xl: '2.5rem',
  xxl: '3rem',
};

export const spacingLayout = {
  s: '25px',
  m: '50px',
  l: '75px',
  xl: '100px',
  xxl: '150px',
};

export const transitionDuration = '0.2s';
