import { Theme } from '@emotion/react';

import { MarketType } from '../consts';
import { weight } from '.';
import { colors } from './tokens';

const commonTheme = {
  cta: {
    icon: colors.white,
    text: colors.white,
    background: colors.green900,
    fontWeight: weight.extrabold,
    border: colors.grey700,
  },
  upholstery: {
    title: {
      transform: 'uppercase',
      decoration: 'none',
    },
  },
};

export const themeConfig: Record<MarketType, Theme> = {
  UNITED_STATES: { ...commonTheme },
  UNITED_KINGDOM: {
    cta: {
      icon: colors.grey800,
      text: colors.grey800,
      background: colors.orange500,
      fontWeight: weight.extrabold,
      border: colors.grey700,
    },
    upholstery: {
      title: {
        transform: 'none',
        decoration: 'underline',
      },
    },
  },
  SPAIN: { ...commonTheme },
  FRANCE: { ...commonTheme },
  ITALY: { ...commonTheme },
  PORTUGAL: { ...commonTheme },
};
