import { css } from '@emotion/react';

import { breaks, spacing } from '../../styles/tokens';

export const content = css`
  align-items: start;
  display: grid;
  gap: ${spacing.m};

  @media ${breaks.midPageWidth} {
    grid-template-columns: 1fr auto;
    gap: ${spacing.xxl};
  }

  p + p {
    margin-top: ${spacing.s};
  }
`;

export const boxout = css`
  max-width: 17rem;
`;
