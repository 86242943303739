/** @jsxImportSource @emotion/react */
export type Props = { color?: string; title?: string };

export function Alert({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M30 5.625C16.56 5.625 5.625 16.56 5.625 30S16.56 54.375 30 54.375 54.375 43.44 54.375 30 43.44 5.625 30 5.625zm0 37.49a2.344 2.344 0 110-4.688 2.344 2.344 0 010 4.688zm2.545-23.573l-.672 14.297a1.875 1.875 0 01-3.75 0l-.673-14.29v-.006a2.55 2.55 0 013.545-2.452 2.547 2.547 0 011.546 2.452h.004z"
        fill={color}
      />
    </svg>
  );
}

export function ArrowDown({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        fill={color}
        d="M60 30c0 16.563-13.437 30-30 30C13.437 60 0 46.563 0 30 0 13.437 13.437 0 30 0c16.563 0 30 13.437 30 30zm-4.615 0C55.385 15.986 44.014 4.615 30 4.615 15.986 4.615 4.615 15.986 4.615 30c0 14.014 11.371 25.385 25.385 25.385 14.014 0 25.385-11.371 25.385-25.385zm-9.418-.497a2.4 2.4 0 01.122 3.263l-.122.131-14.4 14.4a2.4 2.4 0 01-3.263.122l-.131-.122-14.4-14.4a2.4 2.4 0 013.263-3.516l.131.122L27.47 39.806V14.4a2.4 2.4 0 014.794-.164l.006.164v25.406l10.303-10.303a2.4 2.4 0 013.394 0z"
      />
    </svg>
  );
}

export function ArrowLeft({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        fill={color}
        d="M30 0c16.563 0 30 13.437 30 30 0 16.563-13.437 30-30 30C13.437 60 0 46.563 0 30 0 13.437 13.437 0 30 0zm0 4.615C15.986 4.615 4.615 15.986 4.615 30c0 14.014 11.371 25.385 25.385 25.385 14.014 0 25.385-11.371 25.385-25.385C55.385 15.986 44.014 4.615 30 4.615zm.497 9.418a2.4 2.4 0 010 3.394L20.194 27.73H45.6a2.4 2.4 0 110 4.8H20.194l10.303 10.303a2.4 2.4 0 01-3.394 3.394l-14.4-14.4a2.4 2.4 0 010-3.394l14.4-14.4a2.4 2.4 0 013.394 0z"
      />
    </svg>
  );
}

export function ArrowRight({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        fill={color}
        d="M30 0c16.563 0 30 13.437 30 30 0 16.563-13.437 30-30 30C13.437 60 0 46.563 0 30 0 13.437 13.437 0 30 0zm0 4.615C15.986 4.615 4.615 15.986 4.615 30c0 14.014 11.371 25.385 25.385 25.385 14.014 0 25.385-11.371 25.385-25.385C55.385 15.986 44.014 4.615 30 4.615zm-.497 9.418a2.4 2.4 0 013.263-.122l.131.122 14.4 14.4a2.4 2.4 0 01.122 3.263l-.122.131-14.4 14.4a2.4 2.4 0 01-3.516-3.263l.122-.131L39.806 32.53H14.4a2.4 2.4 0 01-.164-4.794l.164-.006h25.406L29.503 17.427a2.4 2.4 0 010-3.394z"
      />
    </svg>
  );
}

export function ChevronDown({
  color = 'currentColor',
  title,
  ...props
}: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M48.864 19.574a2.812 2.812 0 010 3.977L31.989 40.426a2.812 2.812 0 01-3.978 0L11.136 23.551a2.812 2.812 0 113.978-3.977L30 34.46l14.886-14.886a2.812 2.812 0 013.978 0z"
        fill={color}
      />
    </svg>
  );
}

export function ChevronLeft({
  color = 'currentColor',
  title,
  ...props
}: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M40.426 11.136a2.812 2.812 0 010 3.978L25.54 30l14.886 14.886a2.812 2.812 0 11-3.977 3.978L19.574 31.989a2.812 2.812 0 010-3.978l16.875-16.875a2.812 2.812 0 013.977 0z"
        fill={color}
      />
    </svg>
  );
}

export function ChevronRight({
  color = 'currentColor',
  title,
  ...props
}: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M19.574 11.136a2.812 2.812 0 013.977 0l16.875 16.875a2.812 2.812 0 010 3.978L23.551 48.864a2.812 2.812 0 11-3.977-3.978L34.46 30 19.574 15.114a2.812 2.812 0 010-3.978z"
        fill={color}
      />
    </svg>
  );
}

export function Close({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M33.977 30L45.11 18.867a2.815 2.815 0 10-3.977-3.984L30 26.016 18.867 14.883a2.817 2.817 0 10-3.984 3.984L26.016 30 14.883 41.133a2.817 2.817 0 103.984 3.984L30 33.984l11.133 11.133a2.818 2.818 0 003.984-3.984L33.977 30z"
        fill={color}
      />
    </svg>
  );
}

export function Email({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M11.555 0a3 3 0 00-3 3v13.322l-6.583 8.7A4.992 4.992 0 000 29v26a5 5 0 005 5h50a5 5 0 005-5V29a4.997 4.997 0 00-2.358-4.246l-7.087-9.112V3a3 3 0 00-3-3h-36zm39 20.743v10.678l5.496-4.123-.449-.28-5.047-6.275zm-2 12.178V3a1 1 0 00-1-1h-36a1 1 0 00-1 1v28.674l14.036 10.204 12.062-.03 11.902-8.927zm-40-2.701v-8.785L4.2 27.052l4.356 3.168zM3 55V29.708l18.325 13.315L3.003 55.107A2.028 2.028 0 013 55.001zm51.919 2H5.296l18.615-12.306 13.33-.034L54.92 57zM57 30.159v24.819L39.877 43.022 57 30.158zM26.305 10c.75 0 1.25-.8 1.25-2s-.5-2-1.25-2h-9.5c-.75 0-1.25.8-1.25 2s.5 2 1.25 2h9.5zm-9.477 14h25.454c.764 0 1.273.8 1.273 2s-.509 2-1.273 2H16.828c-.764 0-1.273-.8-1.273-2s.51-2 1.273-2zm25.454 7H16.828c-.764 0-1.273.8-1.273 2s.51 2 1.273 2h25.454c.764 0 1.273-.8 1.273-2s-.509-2-1.273-2zM16.828 17h25.454c.764 0 1.273.8 1.273 2s-.509 2-1.273 2H16.828c-.764 0-1.273-.8-1.273-2s.51-2 1.273-2z"
        fill={color}
        fillRule="evenodd"
      />
    </svg>
  );
}

export function Info({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        fill={color}
        d="M30 5.5c13.526 0 24.5 10.974 24.5 24.5S43.526 54.5 30 54.5 5.5 43.526 5.5 30 16.474 5.5 30 5.5zm0 4.692c-10.935 0-19.808 8.873-19.808 19.808S19.065 49.808 30 49.808 49.808 40.935 49.808 30 40.935 10.192 30 10.192zm.24 13.408v.01c1.213.096 2.16.915 2.16 1.91V38h6.08c1.06 0 1.92 1.075 1.92 2.4 0 1.326-.86 2.4-1.92 2.4H21.52c-1.06 0-1.92-1.074-1.92-2.4 0-1.325.86-2.4 1.92-2.4h6.08v-9.6h-3.12c-1.06 0-1.92-1.074-1.92-2.4 0-1.325.86-2.4 1.92-2.4h5.76zM29.5 15a2.5 2.5 0 110 5 2.5 2.5 0 010-5z"
      />
    </svg>
  );
}

export function Menu({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M7.5 18.75c0-1.035.84-1.875 1.875-1.875h41.25a1.875 1.875 0 010 3.75H9.375A1.875 1.875 0 017.5 18.75zM7.5 30c0-1.035.84-1.875 1.875-1.875h41.25a1.875 1.875 0 010 3.75H9.375A1.875 1.875 0 017.5 30zM7.5 41.25c0-1.035.84-1.875 1.875-1.875h41.25a1.875 1.875 0 010 3.75H9.375A1.875 1.875 0 017.5 41.25z"
        fill={color}
      />
    </svg>
  );
}

export function Phone({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M45.82 56.25c-2.287 0-5.5-.828-10.312-3.516-5.851-3.281-10.377-6.31-16.197-12.115-5.61-5.607-8.341-9.238-12.163-16.191C2.831 16.576 3.568 12.46 4.39 10.7c.98-2.102 2.426-3.36 4.295-4.607a20.66 20.66 0 013.356-1.782l.323-.141c.58-.262 1.46-.657 2.573-.235.743.28 1.406.85 2.444 1.875 2.13 2.1 5.04 6.777 6.113 9.074.72 1.548 1.197 2.57 1.199 3.716 0 1.342-.675 2.377-1.495 3.493-.153.21-.305.41-.453.605-.892 1.172-1.088 1.51-.959 2.115.262 1.216 2.21 4.833 5.413 8.029 3.203 3.196 6.716 5.021 7.936 5.281.63.135.976-.069 2.186-.992.173-.133.351-.27.538-.407 1.249-.929 2.235-1.586 3.546-1.586h.007c1.14 0 2.116.494 3.733 1.31 2.11 1.064 6.927 3.936 9.04 6.068 1.028 1.036 1.6 1.697 1.881 2.438.422 1.117.025 1.992-.234 2.578-.044.098-.092.204-.142.323a20.674 20.674 0 01-1.792 3.349c-1.246 1.863-2.508 3.306-4.615 4.287a7.9 7.9 0 01-3.463.758z"
        fill={color}
      />
    </svg>
  );
}

export function Plus({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M30 10c1.42 0 1.875.987 1.976 2.312l.018.338L32 13v15h15c.118 0 .235.002.35.006l.338.018C49.013 28.124 50 28.58 50 30s-.987 1.875-2.312 1.976l-.338.018L47 32H32v15l-.006.35-.018.338C31.876 49.013 31.42 50 30 50s-1.875-.987-1.976-2.312l-.018-.338a9.752 9.752 0 01-.005-.174L28 32H13l-.35-.006-.338-.018C10.987 31.876 10 31.42 10 30s.987-1.875 2.312-1.976l.338-.018.174-.005L28 28V13c0-.118.002-.235.006-.35l.018-.338C28.124 10.987 28.58 10 30 10z"
        fill={color}
      />
    </svg>
  );
}

export function Refresh({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 30 30" aria-label={title} {...props}>
      <path
        d="M13.9355 12.244C14.618 12.1841 15.121 11.5825 15.059 10.9002C14.9971 10.2179 14.3936 9.7133 13.7111 9.77319L6.43635 10.4116C8.29414 7.74266 11.3837 5.99852 14.8782 5.99852C20.5546 5.99852 25.1562 10.6001 25.1562 16.2765C25.1562 21.9528 20.5546 26.5544 14.8782 26.5544C9.20186 26.5544 4.60027 21.9528 4.60027 16.2765C4.60027 15.4032 3.89233 14.6952 3.01905 14.6952C2.14576 14.6952 1.43782 15.4032 1.43782 16.2765C1.43782 23.6994 7.45529 29.7169 14.8782 29.7169C22.3011 29.7169 28.3186 23.6994 28.3186 16.2765C28.3186 8.85355 22.3011 2.83608 14.8782 2.83608C10.5431 2.83608 6.68954 4.88832 4.23276 8.07112L3.62121 1.33749C3.55924 0.655181 2.95574 0.150618 2.27325 0.210515C1.59076 0.270411 1.08773 0.872084 1.14969 1.55439L2.10175 12.0373C2.16372 12.7196 2.76722 13.2242 3.44971 13.1643L4.01845 13.1144C4.10187 13.1137 4.18471 13.1063 4.26622 13.0926L13.9355 12.244Z"
        fill={color}
      />
    </svg>
  );
}

export function Star({ title, ...props }: Props) {
  return (
    <svg viewBox="0 0 41 41" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <g fillRule="nonzero" fill="none">
        <path
          d="M20.5 38.5c9.941 0 18-8.059 18-18s-8.059-18-18-18-18 8.059-18 18 8.059 18 18 18z"
          stroke="#EB7C0F"
          strokeWidth="4"
          fill="#FFF"
        />
        <path
          stroke="#FFF"
          fill="#F0AB00"
          d="M21.242 8.278l-.448-.903-.448.903-3.49 7.038-7.924 1.09-1.027.141.75.716 5.703 5.445-1.362 7.705-.174.988.894-.456 7.077-3.608 6.989 3.607.885.457-.163-.983-1.277-7.71 5.621-5.447.736-.714-1.015-.14-7.836-1.09z"
        />
      </g>
    </svg>
  );
}

export function Tick({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 60 60" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <path
        d="M49.985 13.589c.779.682.858 1.866.176 2.646l-26.25 30a1.875 1.875 0 01-2.737.09l-11.25-11.25a1.875 1.875 0 012.652-2.65l9.833 9.832 24.93-28.492a1.875 1.875 0 012.646-.176z"
        fill={color}
      />
    </svg>
  );
}

export function TickSolid({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 58 58" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <g fill="none" fillRule="evenodd">
        <path
          fill={color}
          fillRule="nonzero"
          d="M0 29C0 12.99 12.99 0 29 0c16.01 0 29 12.99 29 29 0 16.01-12.99 29-29 29C12.99 58 0 45.01 0 29z"
        />
        <path
          fill="#FFF"
          d="M44.303 16.29c.974.818 1.1 2.271.282 3.245l-19.35 23.036a2.304 2.304 0 01-3.476.06l-8.293-9.215a2.303 2.303 0 113.425-3.082l6.52 7.245 17.646-21.007a2.304 2.304 0 013.246-.282z"
        />
      </g>
    </svg>
  );
}

export function Shield({ color = 'currentColor', title, ...props }: Props) {
  return (
    <svg viewBox="0 0 40 54" aria-label={title} {...props}>
      {title && <title>{title}</title>}
      <g fill="none" fillRule="evenodd">
        <path
          fill="#fff"
          fillRule="nonzero"
          d="M19.9978 3.375C26.2201 7.875 31.1089 9 37.1089 9.45V33.3C37.1089 40.95 32.8867 42.75 19.9978 51.075C6.88672 42.975 2.88672 40.95 2.88672 33.3V9.225C8.88672 9 13.7756 7.65 19.9978 3.375Z"
        />
        <path
          fill={color}
          d="M20 5.625C25.5556 9.675 30.2222 10.8 35.5556 11.025V32.625C35.5556 39.375 31.7778 41.175 20 48.6C8.22222 41.175 4.44444 39.375 4.44444 32.625V11.25C9.77778 10.8 14.4444 9.675 20 5.625ZM20 0C12.4444 6.525 7.77778 6.75 0 6.75V32.625C0 42.975 7.11111 45.675 20 54C32.8889 45.675 40 42.975 40 32.625V6.75C32.2222 6.75 27.5556 6.525 20 0Z"
        />
        <path
          fill={color}
          d="M27.3333 19.7998L17.5556 29.9248L12.6667 25.1998L10 27.8998L17.5556 35.5498L30 22.7248L27.3333 19.7998Z"
        />
      </g>
    </svg>
  );
}
