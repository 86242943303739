import { useEffect } from 'react';

const defaultTitle = process.env.REACT_APP_PAGE_TITLE;

export default (title?: string) => {
  useEffect(() => {
    const segments = [];

    if (title) {
      segments.push(title);
    }

    segments.push(defaultTitle);

    document.title = segments.join(' - ');
  }, [title]);
};
