import { DependencyList, useEffect } from 'react';

/**
 * React Hook that resets the scroll position on initial render.
 *
 * @param deps If present, effect will only activate if the values in the list change.
 */
export default (deps: DependencyList = []) => {
  useEffect(() => {
    window.scrollTo(0, 0);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, deps);
};
