/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';
import { useTranslation } from 'react-i18next';

import PhoneNumberCta from '../PhoneNumberCta';
import * as styles from './styles';

type Props = {
  boxed?: boolean;
  testId: string;
  spacing?: boolean;
  centeredMd?: boolean;
  centeredLg?: boolean;
};

export default function CustomFreephone(props: Props) {
  const [common] = useTranslation('common');
  const propStyles = [
    props.spacing && styles.freephoneWrapperSpacing,
    props.centeredMd && styles.freephoneWrapperCenteredMd,
    props.centeredLg && styles.freephoneWrapperCenteredLg,
    props.boxed ? styles.freephoneWrapperBoxed : styles.freephoneWrapper,
  ];
  const freephoneNumberStyles = [
    props.boxed ? styles.freephoneNumberBoxed : styles.freephoneNumber,
  ];
  const freephoneTextStyles = [
    props.boxed ? styles.freephoneTextBoxed : styles.freephoneText,
  ];

  return (
    <Fragment>
      <div css={[...propStyles]}>
        <div css={[...freephoneNumberStyles]}>
          <PhoneNumberCta testId={props.testId} />
        </div>
        <div css={[...freephoneTextStyles]}>
          {common('customFreephoneText')}
        </div>
      </div>
    </Fragment>
  );
}
