import { css } from '@emotion/react';

import { weight } from '../../styles';
import { colors, spacing, transitionDuration } from '../../styles/tokens';

type Options = {
  direction: IconAlignment;
  iconSmall: boolean;
  hasIcon: boolean;
};

export enum IconAlignment {
  'Left',
  'Right',
}

export default ({ direction, iconSmall, hasIcon }: Options) => ({
  base: css`
    appearance: none;
    background: none;
    border: 0;
    color: ${colors.grey800};
    cursor: pointer;
    display: inline;
    padding: 0;
    margin: 0;
    font: inherit;
    ${weight.bold};
    text-align: inherit;
    text-decoration: underline;
    transition: color ${transitionDuration} ease-in-out;

    &:hover {
      color: ${colors.purple500};
    }
  `,
  inner: [
    css`
      align-items: start;
      display: grid;
      gap: ${spacing.xs};
    `,
    hasIcon &&
      css`
        grid-template-columns: ${direction === IconAlignment.Left
          ? `auto 1fr`
          : `1fr auto`};
      `,
  ],
  icon: css`
    &,
    svg {
      height: ${iconSmall ? spacing.s : spacing.m};
      width: ${iconSmall ? spacing.s : spacing.m};
    }
  `,
});
