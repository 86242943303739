import axios, { AxiosError, AxiosRequestConfig } from 'axios';

import { AppErrorId } from '../../consts';
import AppError from '../../utils/AppError';
import {
  getContractTypeResponseData,
  getInitialPrerequisitesResponseData,
  getInstallationAddressResponseData,
  getLeadResponseData,
  getLocationResponseData,
  getPersonalDetailsResponseData,
  postEmailQuoteRequestData,
  postEmailQuoteResponseData,
  postInitialiseResponseData,
  postPayResponseData,
  postScheduleRequestData,
  postScheduleResponseData,
  postStartRequestData,
  postStartResponseData,
  putContractTypeRequestData,
  putInstallationAddressRequestData,
  putLeadRequestData,
  putMarketingCampaignRequestData,
  putPersonalDetailsRequestData,
  putPrerequisitesUpdateRequestData,
  putProductConditionRequestData,
  putScheduleRequestData,
  putScheduleResponseData,
  putUpdateRequestData,
  putUpdateResponseData,
} from './types';

export const baseURL =
  process.env.REACT_APP_USE_MOCK === 'false' &&
  process.env.NODE_ENV === 'development'
    ? '/v1'
    : '/api/v1';
export const csrfHeaderKey = 'X-CSRF-TOKEN';
const errorMap: Record<string, AppErrorId> = {
  '403': AppErrorId.Api403,
  '404': AppErrorId.Api404,
  '410': AppErrorId.Api410,
};

const service = axios.create({
  baseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  withCredentials: true,
});

service.interceptors.response.use(undefined, (error: AxiosError) => {
  if (axios.isCancel(error)) {
    throw new AppError(AppErrorId.ApiCancelled);
  } else {
    const status = error.response?.status.toString();

    throw new AppError(
      status && status in errorMap ? errorMap[status] : AppErrorId.Unspecific
    );
  }
});

// Check if the CSRF header is set and run a sync request if it's not
service.interceptors.request.use(config => {
  const syncURL = '/stairlift/sync';
  const startURL = '/stairlift/start';

  const isCsrfSet =
    config.url === syncURL || service.defaults.headers.common[csrfHeaderKey];

  const isNewSession = config.url === startURL;

  // If CSRF header is already set return the config. Unless it's a /start call
  if (isCsrfSet && !isNewSession) {
    return config;
  }

  const patchRequestWithToken = (token = '') => {
    // Add token as a default header for subsequent requests
    service.defaults.headers.common[csrfHeaderKey] = token;

    // Return the config for current request, with CSRF header set
    return {
      ...config,
      headers: { ...config.headers, [csrfHeaderKey]: token },
    };
  };

  return service
    .post<{ token: string }>(syncURL, null)
    .then(({ data: { token } }) => patchRequestWithToken(token))
    .catch(() => patchRequestWithToken(undefined));
});

// Set a buildID for all HTTP API requests
service.interceptors.request.use(request => {
  if (request.headers) {
    request.headers['build-id'] = process.env.REACT_APP_BUILD_ID ?? 'null';
  }
  return request;
});

export const postStart = (
  data: postStartRequestData,
  config?: AxiosRequestConfig
) => service.post<postStartResponseData>('/stairlift/start', data, config);

export const postInitialise = (config?: AxiosRequestConfig) =>
  service.post<postInitialiseResponseData>(
    '/stairlift/initialise',
    null,
    config
  );

export const putUpdate = (
  data: putUpdateRequestData,
  config?: AxiosRequestConfig
) => service.put<putUpdateResponseData>('/stairlift/update', data, config);

export const postDetails = (config?: AxiosRequestConfig) =>
  service.post('/stairlift/details', null, config);

export const putProductCondition = (
  productCondition?: putProductConditionRequestData,
  config?: AxiosRequestConfig
) =>
  service.put('/stairlift/details:productCondition', productCondition, config);

export const paymentDetails = (config?: AxiosRequestConfig) => {
  return service.post<postPayResponseData>('/stairlift/pay', null, {
    ...config,
    headers: {
      ...config?.headers,
    },
  });
};

export const postPayConfirm = (config?: AxiosRequestConfig) =>
  service.post('/stairlift/pay:confirm', null, config);

export const postSchedule = (
  data: postScheduleRequestData,
  config?: AxiosRequestConfig
) =>
  service.post<postScheduleResponseData>('/stairlift/schedule', data, config);

export const putSchedule = (
  data: putScheduleRequestData,
  config?: AxiosRequestConfig
) => service.put<putScheduleResponseData>('/stairlift/schedule', data, config);

export const getInitialPrerequisites = (config?: AxiosRequestConfig) =>
  service.get<getInitialPrerequisitesResponseData>(
    '/stairlift/prerequisites/initialise',
    config
  );

export const putPrerequisitesUpdate = (
  data: putPrerequisitesUpdateRequestData,
  config?: AxiosRequestConfig
) => service.put('/stairlift/prerequisites/update', data, config);

export const getLocation = (zip: string, config?: AxiosRequestConfig) =>
  service.get<getLocationResponseData>(`/location/${zip}`, config);

export const getPersonalDetails = (config?: AxiosRequestConfig) =>
  service.get<getPersonalDetailsResponseData>('/stairlift/personal-details', {
    ...config,
    data: null,
  });

export const putPersonalDetails = (
  data: putPersonalDetailsRequestData,
  config?: AxiosRequestConfig
) => service.put('/stairlift/personal-details', data, config);

export const getInstallationAddress = (config?: AxiosRequestConfig) =>
  service.get<getInstallationAddressResponseData>(
    '/stairlift/installation-address',
    {
      ...config,
      data: null,
    }
  );

export const putInstallationAddress = (
  data: putInstallationAddressRequestData,
  config?: AxiosRequestConfig
) => service.put('/stairlift/installation-address', data, config);

export const getContractType = (config?: AxiosRequestConfig) =>
  service.get<getContractTypeResponseData>('/stairlift/contract-type', config);

export const putContractType = (
  data: putContractTypeRequestData,
  config?: AxiosRequestConfig
) => service.put('/stairlift/contract-type', data, config);

export const putMarketingCampaign = (
  data: putMarketingCampaignRequestData,
  config?: AxiosRequestConfig
) => service.put('/stairlift/marketing-campaign', data, config);

export const postEmailQuote = (
  data: postEmailQuoteRequestData,
  config?: AxiosRequestConfig
) =>
  service.post<postEmailQuoteResponseData>(
    '/stairlift/email-quote',
    data,
    config
  );

export const getLead = (config?: AxiosRequestConfig) =>
  service.get<getLeadResponseData>('/stairlift/lead', config);

export const putLead = (
  data: putLeadRequestData,
  config?: AxiosRequestConfig
) => service.put('/stairlift/lead', data, config);
