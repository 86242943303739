/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import useStyles from './useStyles';

type Props = {
  delay?: number;
  overlay?: boolean;
  silent?: boolean;
  small?: boolean;
  text?: string;
};

export default function Loading({
  delay,
  overlay,
  silent = false,
  small = false,
  text,
  ...props
}: Props) {
  const styles = useStyles({ overlay, small });
  const [common] = useTranslation('common');
  const [isVisible, setVisibility] = useState(!delay);

  useEffect(() => {
    if (!delay) {
      return undefined;
    }

    const timer = setTimeout(() => {
      setVisibility(true);
    }, delay);

    return () => {
      clearTimeout(timer);
    };
  }, [delay]);

  return isVisible ? (
    <div css={styles.base} data-test-id="loading" {...props}>
      <div css={styles.inner} role={silent ? undefined : 'alert'}>
        {!silent && <span>{text || common('loading')}</span>}
      </div>
    </div>
  ) : null;
}
