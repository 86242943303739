/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import * as styles from './styles';

type Props = { as?: string; children: ReactNode; role?: string };

export default function ContentContainer({
  as: Element = 'div',
  ...props
}: Props) {
  return <Element css={styles.base} {...props} />;
}
