/** @jsxImportSource @emotion/react */
import { css, Global } from '@emotion/react';

import { FAST_FONTS_URL, FontFamily, FontId } from '../../consts';
import futura400Woff2 from '../../fonts/futura-400/464e1370-5b73-493b-a369-ee8ed8d2d36e.woff2';
import futura400Woff from '../../fonts/futura-400/e23cba8f-ab32-49b8-95d3-3dad0150b369.woff';
import futura500Woff from '../../fonts/futura-500/12a2ea1a-ff0f-48ec-b350-21d2115f2b88.woff';
import futura500Woff2 from '../../fonts/futura-500/61d9e32c-2fc7-4804-967b-0bc17f76f10d.woff2';
import futura700Woff from '../../fonts/futura-700/2fcfc1da-a7af-42d2-87f4-6613b3e5c900.woff';
import futura700Woff2 from '../../fonts/futura-700/480df9d4-0a52-44dc-a6ea-32b77ac0dfa5.woff2';
import { weight } from '../../styles';
import { colors, transitionDuration } from '../../styles/tokens';
import fluidStyles from '../../utils/fluidStyles';

const fluidFontSize = fluidStyles(
  ['18px', '20px'],
  value => `font-size: ${value};`
);

export default function GlobalStyles() {
  return (
    <Global
      styles={css`
        ${process.env.NODE_ENV === 'development'
          ? ''
          : `@import url("${FAST_FONTS_URL}=${FontId.Weight400},${FontId.Weight500},${FontId.Weight700}");`}
        /*
         * This CSS resource incorporates links to font software which is the valuable
         * copyrighted property of Monotype and/or its suppliers. You may not attempt
         * to copy, install, redistribute, convert, modify or reverse engineer this font
         * software. Please contact Monotype with any questions regarding Web Fonts:
         * http://www.fonts.com
         *
         * Fonts.com Futura IDs
         * ---
         * These are needed to track font usage.
         *
         * - 5575027 = weight 500
         * - 5575015 = weight 400
         * - 5574941 = weight 700
         */

        @font-face {
          font-display: swap;
          font-family: '${FontFamily.Primary}';
          ${weight.normal}
          src: url('${futura400Woff2}') format('woff2'),
            url('${futura400Woff}') format('woff');
        }

        @font-face {
          font-display: swap;
          font-family: '${FontFamily.Primary}';
          ${weight.bold};
          src: url('${futura500Woff2}') format('woff2'),
            url('${futura500Woff}') format('woff');
        }

        @font-face {
          font-display: swap;
          font-family: '${FontFamily.Primary}';
          ${weight.extrabold};
          src: url('${futura700Woff2}') format('woff2'),
            url('${futura700Woff}') format('woff');
        }

        @font-face {
          font-family: '${FontFamily.Primary}';
          src: local('${FontFamily.Secondary}');
          ${weight.normal};
          unicode-range: U+3F, U+BF;
        }

        @font-face {
          font-family: '${FontFamily.Primary}';
          src: local('${FontFamily.Tertiary}'), local('${FontFamily.Secondary}');
          ${weight.bold};
          unicode-range: U+3F, U+BF;
        }

        @font-face {
          font-family: '${FontFamily.Primary}';
          src: local('${FontFamily.Tertiary}'), local('${FontFamily.Secondary}');
          ${weight.extrabold};
          unicode-range: U+3F, U+BF;
        }

        * {
          box-sizing: border-box;
        }

        :root {
          ${fluidFontSize}

          @media print {
            font-size: 12pt;
          }
        }

        html {
          -webkit-text-size-adjust: 100%;
        }

        body {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: ${colors.grey800};
          font-family: '${FontFamily.Primary}', -apple-system,
            BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
            'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
          font-size: 100%;
          letter-spacing: 0.01em;
          line-height: 1.5;
          margin: 0;
        }

        html,
        body {
          height: 100%;
        }

        main {
          display: block;
        }

        @media print {
          * {
            margin: 0;
            padding: 0;
          }
          @page {
            size: A4 portrait;
            margin: 0;
          }
          html,
          body {
            float: none !important;
          }
        }

        #app-root {
          display: grid;
          grid-template-rows: auto 1fr auto;
          min-height: 100%;
        }

        strong {
          ${weight.bold};
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ol,
        ul {
          margin: 0;
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
          ${weight.normal};
        }

        a {
          ${weight.bold};
          color: ${colors.grey800};
          transition: color ${transitionDuration} ease-in-out;

          &:hover {
            color: ${colors.purple500};
          }

          &[href^='tel:'] {
            color: ${colors.red500};
            white-space: nowrap;

            &:hover {
              color: ${colors.purple500};
            }
          }
        }

        img {
          display: block;
        }

        .grecaptcha-badge {
          visibility: hidden;
        }
      `}
    />
  );
}
