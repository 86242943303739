import { css } from '@emotion/react';

import { colors, spacing } from '../../styles/tokens';

export const base = css`
  background-color: ${colors.grey100};
  border-radius: ${spacing.xxs};
  margin: 0 auto;
  max-width: 41rem;
  padding: ${spacing.l};
  text-align: center;
`;
