import { breaks, layout } from '../../styles/tokens';
import { MinMax } from '../../types';

const stripUnit = (value: string) => parseFloat(value.replace(/[^\d.-]/g, ''));

/**
 * Utility that takes a min/max range of values and a template to interpolate the computed values. A CSS string is returned.
 *
 * @param range - Accepts a single string for a fixed range or a tuple containing a `min` and `max` string.
 * @param template - Function that provides the computed value. A valid CSS string should be returned.
 * @returns CSS string
 */
export default (
  range: string | MinMax,
  template: (value: string) => string
): string => {
  if (typeof range === 'string') {
    return template(range);
  }

  const [min, max] = range;
  const minUnitless = stripUnit(min);
  const maxUnitless = stripUnit(max);

  const valueDiff = maxUnitless - minUnitless;
  const layoutDiff = layout.maxPageWidth - layout.minPageWidth;

  return `
    ${template(min)}

    @media ${breaks.minPageWidth} {
      ${template(
        `calc(${min} + ${valueDiff} * (100vw - ${layout.minPageWidth}px) / ${layoutDiff})`
      )}
    }

    @media ${breaks.maxPageWidth} {
      ${template(max)}
    }
  `;
};
