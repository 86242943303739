import { css } from '@emotion/react';

import { colors } from '../../styles/tokens';

export const markdown = css`
  a {
    color: ${colors.blue700};
  }
  a[href*='###'] {
    color: ${colors.grey800};
  }
`;
