export enum AnalyticsStepLegacy {
  Home = '1',
  Config = '2',
  Details = '3',
  Schedule = '4',
  PersonalDetails = '5',
  InstallationAddress = '6',
  Payment = '7',
  PaymentSuccessful = '8',
}

export enum AnalyticsPageId {
  Home = 'HOME',
  Prerequisite = 'PREREQUISITE',
  Config = 'CONFIG',
  Details = 'DETAILS',
  ContractType = 'CONTRACT_TYPE',
  Schedule = 'SCHEDULE',
  Lead = 'LEAD',
  PersonalDetails = 'PERSONAL_DETAILS',
  InstallationAddress = 'INSTALLATION_ADDRESS',
  Payment = 'PAYMENT',
  PaymentSuccessful = 'PAYMENT_SUCCESSFUL',
  PaymentCancelled = 'PAYMENT_CANCELLED',
  Confirmation = 'CONFIRMATION',
}

export enum AppErrorId {
  Unspecific = 'unspecific',
  App404 = 'notFound',
  ApiCancelled = 'apiCancelled',
  Api403 = 'api403',
  Api404 = 'api404',
  Api410 = 'api410',
}

export enum AppInsightsKey {
  Dev = 'fe5a028d-13d6-47e2-9764-ef537889bb69',
  Test = '701828bb-6f74-4d2a-b0fc-6f3deadcf3fd',
  Staging = '28a289af-c890-4aa6-b4f8-3b8b15e68a54',
  Live = '89ba3d1e-2dfd-4350-95a3-50ed98e7629a',
}

export enum ContractType {
  Purchase = 'PURCHASE',
  Rental = 'RENTAL',
}

export enum ProductCondition {
  New = 'NEW',
  Reconditioned = 'RECONDITIONED',
}

export enum MarketingCampaign {
  Ecommerce = 'ECOMMERCE',
  GoInstore = 'GOINSTORE',
}

export enum Environment {
  Unknown = 'UNKNOWN',
  Dev = 'DEV',
  Test = 'TEST',
  Staging = 'STAGING',
  Live = 'LIVE',
}

export enum Experiment {
  Id = '',
  Interval = 100,
  Timeout = 2000,
}

export enum GoogleReCaptchaSiteKey {
  NonLive = '6LcRyYUaAAAAAN3dSs9sUQ5a9PFSk-ZXCCtxExFX',
  Live = '6LcM44kaAAAAAId3Wthu9Y9BH2s93r3MTFwakCr6',
}

export enum GoogleReCaptchaAction {
  EmailQuoteSubmit = 'EMAIL_QUOTE_SUBMIT',
  LeadSubmit = 'LEAD_SUBMIT',
}

export enum FabricType {
  // wc - Woven
  RedW = 'wc-red',
  StoneW = 'wc-stone',
  GreenW = 'wc-green',
  BlueW = 'wc-blue',
  MustardW = 'wc-mustard',

  // flc - Faux Leather (Starla only)
  BeigeFL = 'flc-beige',
  BrownFL = 'flc-brown',

  // qflc - Quilted Faux Leather
  BlackQFL = 'qflc-black',
  MaroonQFL = 'qflc-maroon',
  CocoaQFL = 'qflc-cocoa',
  DamsonQFL = 'qflc-damson',
  NavyBlueQFL = 'qflc-navy_blue',
  GreenQFL = 'qflc-green',
  GreyQFL = 'qflc-grey',
  SandQFL = 'qflc-sand',
  DuckEggBlueQFL = 'qflc-duck_egg_blue',
  TealQFL = 'qflc-teal',

  // flc - Faux Leather (Siena only)
  WalnutFL = 'flc-walnut',
  IvoryFL = 'flc-ivory',
  PineFL = 'flc-pine',
  CranberryFL = 'flc-cranberry',
  MidnightFL = 'flc-midnight',

  // vc - Velvet
  ChocolateV = 'vc-chocolate',
  PistachioV = 'vc-pistachio',
  PurpleV = 'vc-purple',
  SkyBlueV = 'vc-sky_blue',
  TurquoiseV = 'vc-turquoise',

  // wflcc - Woven and Faux Leather Combination
  PlumWFLC = 'wflcc-plum',
  AvocadoWFLC = 'wflcc-avocado',
  OatmealWFLC = 'wflcc-oatmeal',
  CherryWFLC = 'wflcc-cherry',
  SlateWFLC = 'wflcc-slate',

  // dwc - Designer Woven
  AcuteDW = 'dwc-acute',
  AutumnDW = 'dwc-autumn',
  CircusDW = 'dwc-circus',
  CubicDW = 'dwc-cubic',
  JazzDW = 'dwc-jazz',

  // dsc - Designer Soft
  PoppyDS = 'dsc-poppy',
  HeritageDS = 'dsc-heritage',

  // vyc - Vinyl
  LavenderVY = 'vyc-lavender',
  SandVY = 'vyc-sand',
}

export enum MarketType {
  ES = 'SPAIN',
  FR = 'FRANCE',
  IT = 'ITALY',
  PT = 'PORTUGAL',
  UK = 'UNITED_KINGDOM',
  US = 'UNITED_STATES',
}

export enum ModalSizeType {
  Narrow,
  NarrowFullWidth,
  Wide,
  WideFullWidth,
}

export enum ModalReconLayout {
  Primary,
  Secondary,
}

export enum OptionCategory {
  Colour = 'COLOUR',
  Electrical = 'ELECTRICAL',
  Hinge = 'HINGE',
  NumberOfTurns = 'NUMBER_OF_TURNS',
  Obstruction = 'OBSTRUCTION',
  PackageType = 'PACKAGE_TYPE',
  PlugSocketInstallation = 'PLUG_SOCKET_INSTALLATION',
  SitePrepGroup = 'SITE_PREP_GROUP',
  StairliftTurnCount = 'STAIRLIFT_TURN_COUNT',
  Swivel = 'SWIVEL',
  Trim = 'TRIM',
  Upsell = 'UPSELL',
  Warranty = 'WARRANTY',
}

export enum OptionGroup {
  PackageType = 'PACKAGE_TYPE',
  Product = 'PRODUCT',
  ProductAddOn = 'PRODUCT_ADD_ON',
  Service = 'SERVICE',
  SitePrep = 'SITE_PREP',
  SitePrepGroup = 'SITE_PREP_GROUP',
}

export enum OptionType {
  ExtendedWarranty = 'EXTENDED_WARRANTY',
  Other = 'OTHER',
  Rail = 'RAIL',
  RailSitePrep = 'RAIL_SITE_PREP',
  RailTurn = 'RAIL_TURN',
  Seat = 'SEAT',
  SitePrep = 'SITE_PREP',
  Stairlift = 'STAIRLIFT',
}

export enum PackageType {
  Standard = 'STANDARD',
  Deluxe = 'DELUXE',
}

export enum PrerequisiteType {
  ZipCode = 'ASK_ZIP_CODE',
}

export enum QuestionType {
  EasyTurn = 'SELECT_POWERED_SWIVEL',
  Fit = 'CONFIRM_FIT',
  Obstructions = 'SELECT_OBSTRUCTIONS',
  OfflineLead = 'SELECT_OFFLINE_LEAD',
  Package = 'SELECT_PACKAGE',
  PlugSocket = 'SELECT_PLUG_SOCKET',
  Rail = 'SELECT_RAIL',
  Stairlift = 'SELECT_STAIRLIFT',
  Turns = 'SELECT_TURNS',
  Upholstery = 'SELECT_UPHOLSTERY',
}

export enum RailType {
  Curved = 'curved',
  Straight = 'straight',
}

export enum ResponseType {
  Config = 'CONFIG',
  Question = 'QUESTION',
  Details = 'DETAILS',
  Payment = 'PAYMENT_INFO',
  Prerequisite = 'PREREQUISITE',
  Contract = 'CONTRACT',
}

export const SCHEDULE_CHOICE_FORMAT = "yyyy-MM-dd'T'HH:mm:ss";

export enum ScheduleType {
  AsSoonAsPossible = 'ASAP',
  Custom = 'CUSTOM',
}

export enum SeatType {
  Siena = 'siena',
  Starla = 'starla',
  Sofia = 'sofia',
  Solus = 'solus',
}

export enum SeatSize {
  Standard = 'standard',
  Wide = 'wide',
}

export enum SessionKey {
  Notice = 'notice',
  Confirmation = 'confirmation',
  Campaign = 'campaign',
}

export enum TrimType {
  None = 'nwt',
  Light = 'lwt',
  Dark = 'dwt',
}

export enum PageId {
  Home = 'home',
  PrerequisitesZipCode = 'prerequisites-zip-code',
  ConfigConfirmation = 'config-confirmation',
  ConfigEasyTurn = 'config-easy-turn',
  ConfigObstructions = 'config-obstructions',
  ConfigOfflineLead = 'config-offline-lead',
  ConfigPackage = 'config-package',
  ConfigPlugSocket = 'config-plug-socket',
  ConfigRail = 'config-rail',
  ConfigStairlift = 'config-stairlift',
  ConfigTurns = 'config-turns',
  ConfigUpholstery = 'config-upholstery',
  Details = 'details',
  Contract = 'contract',
  Schedule = 'schedule',
  PersonalDetails = 'personal-details',
  InstallationAddress = 'installation-address',
  Lead = 'lead',
  Payment = 'payment',
  PaymentCancelled = 'payment-cancelled',
  Confirmation = 'confirmation',
  Error = 'error',
}

export enum UpholsteryAnswerType {
  Fabric = 'fabric',
  Trim = 'trim',
}

export enum WorldPayStatusType {
  Cancelled = 'cancelled_by_shopper',
  Success = 'success',
  Failure = 'failure',
  Error = 'error',
  Processed = 'already_processed',
}

export const TRUST_PILOT_SRC =
  '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js';

export enum TrustPilotWidgetType {
  Horizontal = '5406e65db0d04a09e042d5fc',
  Carousel = '53aa8912dec7e10d38f59f36',
  Mini = '53aa8807dec7e10d38f59f32',
  MiniCarousel = '539ad0ffdec7e10e686debd7',
}

export enum FontFamily {
  Primary = 'Futura BT',
  Secondary = 'Arial',
  Tertiary = 'Tahoma',
}

export const FAST_FONTS_URL =
  '//fast.fonts.net/lt/1.css?apiType=css&c=818fb387-794d-457a-b013-d68215185ef7&fontids=';

export enum FontId {
  Weight400 = '5575015',
  Weight500 = '5575027',
  Weight700 = '5574941',
}

export enum Progress {
  minStep = 0,
  maxStep = 100,
  minSection = 0,
  minProgress = 1,
}

export enum GoInStore {
  evenListener = 'goinstore:connected',
}
