/** @jsxImportSource @emotion/react */
import { useTranslation } from 'react-i18next';

export default function Logo() {
  const [common] = useTranslation('common');
  const title = common('logo');

  return (
    <svg viewBox="0 0 220 42.588" height="1em" aria-label={title}>
      <title>{title}</title>
      <path
        d="M52.769 33.94v8.241a16.987 16.987 0 01-4.02.4c-9.312 0-15.427-6.255-15.427-15.567V6.275h9.041v8.179h9.925v7.372h-9.925v4.656c0 5.681 3.737 8.443 10.406 7.458zm16.048-20.355a11.931 11.931 0 018.536 3.2l.7.737v-3.05h9.017v27.16h-9.026v-3.385a12.789 12.789 0 01-.823 1.04c-1.831 2.157-4.547 3.236-8.3 3.3-7.675 0-13.689-6.387-13.689-14.527S61.2 13.585 68.817 13.585zm2.576 7.628a6.79 6.79 0 106.783 6.793 6.79 6.79 0 00-6.783-6.793zm97.188-7.628a11.931 11.931 0 018.536 3.2l.7.737v-3.05h9.017v27.16h-9.025v-3.385a12.787 12.787 0 01-.823 1.04c-1.824 2.157-4.54 3.236-8.3 3.3-7.675 0-13.681-6.387-13.681-14.527s5.964-14.475 13.576-14.475zm2.553 7.628a6.79 6.79 0 106.79 6.79 6.79 6.79 0 00-6.79-6.79zm-66.054-8.187a24.274 24.274 0 00-14.217 4.532v24.056h9.017v-18.8l.178-.163a7.411 7.411 0 014.656-1.4c3.64 0 6.115 2.328 6.115 5.937v14.458h8.986V28.795c.002-10.026-5.361-15.769-14.735-15.769zm32.414 0a24.25 24.25 0 00-14.217 4.532v24.056h9.017v-18.8l.178-.163a7.411 7.411 0 014.656-1.4c3.639 0 6.115 2.328 6.115 5.937v14.458h9.017V28.795c-.031-10.026-5.4-15.769-14.768-15.769zm-119.351 3.88c-4.842-.892-7.124-1.622-7.124-4.439 0-2.258 2.2-3.826 5.347-3.826a14.962 14.962 0 018.373 2.665l4.043-7.67A23.87 23.87 0 0015.637.006C7.263.006 1.412 5.281 1.412 12.824c0 6.713 4.439 11.159 12.5 12.494 3.655.613 6.984 1.42 6.984 4.555 0 1.8-1.024 3.942-5.905 3.942a16.25 16.25 0 01-10.769-4.656L0 36.966a22.5 22.5 0 0015.132 5.618c9.227 0 15.683-5.5 15.683-13.386 0-6.278-4.625-10.748-12.672-12.269zm187.1-3.88a24.514 24.514 0 00-5.2.551V.936h-9.025v40.71h9.025V22.819l.171-.163a7.434 7.434 0 014.656-1.4c3.647 0 6.115 2.328 6.115 5.937v14.453H220V28.795c-.031-10.026-5.393-15.769-14.76-15.769z"
        fill="#cd202c"
      />
    </svg>
  );
}
