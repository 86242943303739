// This file is the entry point for the bundle
// We ignore this from test coverage as there is nothing to test
// The role of this file is to import and polyfills needed and then
// boot the application

/* istanbul ignore file */
import './polyfills';

import boot from './boot';

boot();
