/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import ContentContainer from '../ContentContainer';
import * as styles from './styles';

type Props = { children?: ReactNode; heading: string };

export default function PageHeader({ children, heading }: Props) {
  return (
    <ContentContainer as="header">
      <h2 css={styles.title}>{heading}</h2>
      {children}
    </ContentContainer>
  );
}
