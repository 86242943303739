import { MetaHTMLAttributes, useEffect, useRef } from 'react';

export default (attributes: MetaHTMLAttributes<HTMLMetaElement>) => {
  const metaTag = useRef(document.createElement('meta'));

  useEffect(() => {
    Object.entries(attributes).forEach(attribute =>
      metaTag.current.setAttribute(...attribute)
    );

    if (!metaTag.current.parentNode) {
      document.head.appendChild(metaTag.current);
    }
  }, [attributes]);
};
