import { createContext, ReactNode, useState } from 'react';

type AppContextProviderProps = {
  children: ReactNode;
};

type AppContextProps = {
  detailsPageSelectedTab: number;
  ctxSetDetailsPageSelectedTab: (index: number) => void;
};

export const AppContext = createContext<AppContextProps | null>(null);

export const AppContextProvider = (props: AppContextProviderProps) => {
  // Details Page Tab
  const [detailsPageSelectedTab, setDetailsPageSelectedTab] = useState(0);
  const ctxSetDetailsPageSelectedTab = (index: number) => {
    setDetailsPageSelectedTab(index);
  };

  return (
    <AppContext.Provider
      value={{
        detailsPageSelectedTab,
        ctxSetDetailsPageSelectedTab,
      }}
    >
      {props.children}
    </AppContext.Provider>
  );
};

export default AppContextProvider;
