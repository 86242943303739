/** @jsxImportSource @emotion/react */
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

import ErrorPage from '../../../components/ErrorPage';
import { AppErrorId } from '../../../consts';

export default function BlueGreenErrorPage() {
  const [config] = useTranslation('config');
  const [errors] = useTranslation('errors');
  const history = useHistory();
  const errorContent = errors(AppErrorId.Api410);

  useEffect(() => {
    sessionStorage.clear();
  }, []);

  return (
    <ErrorPage
      {...errorContent}
      onRetry={() => {
        history.push(config('urls.home'));
      }}
      refresh
    />
  );
}
