import { css } from '@emotion/react';

import fluidStyles from '../utils/fluidStyles';
import { layout, pageGridGap, spacing } from './tokens';

export const fluidImg = css`
  max-width: 100%;
  height: auto;
`;

export const fieldGrid = css`
  display: grid;
  gap: ${spacing.l};
  max-width: ${layout.maxFieldWidth};
  margin-top: ${spacing.l};
`;

export const hiddenOnPrint = css`
  @media print {
    display: none;
  }
`;

export const visibleOnPrint = css`
  display: none;
  @media print {
    display: grid;
  }
`;

export const pageGrid = {
  container: css`
    display: grid;
    ${fluidStyles(pageGridGap, value => `grid-column-gap: ${value};`)}
    grid-template-columns:
      [full-start] minmax(0, 1fr) [main-start] minmax(
        0,
        ${layout.maxPageWidth}px
      )
      [main-end] minmax(0, 1fr) [full-end];

    > * {
      grid-column: main;
    }
  `,
  full: css`
    grid-column: full;
  `,
};

export const visuallyHidden = css`
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
`;

/** Font sizes */
export const text = {
  /** Body text */
  base: css`
    font-size: 1rem;
    line-height: 1.5;
  `,
  /** Text size 1 */
  size1: css`
    font-size: 2.2rem;
    line-height: 1.2;
  `,
  /** Text size 2 */
  size2: css`
    font-size: 1.7rem;
    line-height: 1.2;
  `,
  /** Text size 3 */
  size3: css`
    font-size: 1.4rem;
    line-height: 1.3333;
  `,
  /** Text size 4 */
  size4: css`
    font-size: 1.2rem;
    line-height: 1.4;
  `,
  /** Text size 5 */
  size5: css`
    font-size: 1rem;
    line-height: 1.5;
  `,
  /** Text size 6 */
  size6: css`
    font-size: 0.9rem;
    line-height: 1.5;
  `,
  /** Text size 7 */
  size7: css`
    font-size: 0.77rem;
    line-height: 1.5;
  `,
  /** Text size 7 */
  size7Print: css`
    font-size: 0.77rem;
    line-height: 1.15;
  `,
  /** Text size 8 */
  size8: css`
    font-size: 0.65rem;
    line-height: 1.25;
  `,
};

/** Font weights */
export const weight = {
  normal: css`
    font-weight: 400;
  `,
  bold: css`
    font-weight: 500;
  `,
  extrabold: css`
    font-weight: 700;
  `,
};

export const heading = {
  /** Heading size 1 */
  size1: css`
    ${text.size1};
    ${weight.bold};
  `,
  /** Heading size 2 */
  size2: css`
    ${text.size2};
    ${weight.bold};
  `,
  /** Heading size 3 */
  size3: css`
    ${text.size3};
    ${weight.bold};
  `,
  /** Heading size 4 */
  size4: css`
    ${text.size4};
    ${weight.bold};
  `,
  /** Heading size 5 */
  size5: css`
    ${text.size5};
    ${weight.bold};
  `,
  /** Heading size 6 */
  size6: css`
    ${text.size6};
    ${weight.bold};
  `,
};

export const mt = {
  s: css`
    margin-top: ${spacing.s};
  `,
};

export const mb = {
  s: css`
    margin-bottom: ${spacing.s};
  `,
};

export const ml = {
  xxs: css`
    margin-left: ${spacing.xxs};
  `,
};
