import { AppInsightsKey, Environment } from '../../consts';
import getEnv from '../getEnv';

export default function getAppInsightsKey(): AppInsightsKey | null {
  switch (getEnv()) {
    case Environment.Dev:
      return AppInsightsKey.Dev;
    case Environment.Test:
      return AppInsightsKey.Test;
    case Environment.Staging:
      return AppInsightsKey.Staging;
    case Environment.Live:
      return AppInsightsKey.Live;
    default:
      return null;
  }
}
