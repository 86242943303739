/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import { pageGrid } from '../../styles';

type Props = { as?: string; children: ReactNode; full?: boolean };

export default function PageContent({
  as: Element = 'div',
  full = false,
  ...props
}: Props) {
  return <Element css={full && pageGrid.full} {...props} />;
}
