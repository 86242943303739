/** @jsxImportSource @emotion/react */
import { SerializedStyles, useTheme } from '@emotion/react';
import { MouseEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import { CtaText, Image, TestIdProp } from '../../types';
import imagePath from '../../utils/imagePath';
import useInfinityTracking from '../../utils/useInfinityTracking';
import useMatelsoTracking from '../../utils/useMatelsoTracking';
import ExternalLink from '../ExternalLink';
import { ArrowRight, Props as IconProps } from '../Icons';
import useStyles from './useStyles';

type Props = CtaText &
  Required<TestIdProp> & {
    block?: boolean;
    disabled?: boolean;
    href?: string;
    icon?: (props: IconProps) => JSX.Element;
    image?: Image;
    onClick?: (event: MouseEvent<HTMLElement>) => void;
    restrictTheme?: boolean;
    iconTheme?: boolean;
  };

export default function Cta({
  block = false,
  disabled,
  href,
  icon: Icon = ArrowRight,
  image,
  testId,
  primaryText,
  secondaryText,
  restrictTheme = false,
  ...props
}: Props) {
  const theme = useTheme();
  const styles = useStyles({ block, disabled, hasImage: !!image, theme });

  const [config] = useTranslation('config');
  const supportsTheme = config('features.hasTheme');

  useInfinityTracking();

  useMatelsoTracking();

  const themeStylesSwitcher = (baseStyles: (false | SerializedStyles)[]) => {
    if (supportsTheme && !restrictTheme) {
      return [baseStyles, styles.themed];
    }
    return [baseStyles];
  };

  const content = (
    <div css={styles.outer}>
      {image && (
        <span
          style={{
            backgroundImage: `url(${imagePath(image.src)})`,
          }}
          title={image.alt}
          css={styles.image}
        />
      )}
      <div css={styles.inner}>
        <div>
          <span>{primaryText}</span>
          {secondaryText && (
            <span css={styles.secondaryText}>{secondaryText}</span>
          )}
        </div>
        <Icon css={themeStylesSwitcher(styles.icon)} />
      </div>
    </div>
  );

  if (href) {
    if (href.startsWith('http')) {
      return (
        <ExternalLink
          css={themeStylesSwitcher(styles.base)}
          data-test-id={testId}
          href={href}
          {...props}
        >
          {content}
        </ExternalLink>
      );
    }

    if (href.startsWith('tel:')) {
      return (
        <a
          css={themeStylesSwitcher(styles.base)}
          data-test-id={testId}
          className="InfinityNumber"
          href={href}
          {...props}
        >
          {content}
        </a>
      );
    }

    return (
      <Link css={styles.base} data-test-id={testId} to={href} {...props}>
        {content}
      </Link>
    );
  }

  return (
    <button
      css={themeStylesSwitcher(styles.base)}
      data-test-id={testId}
      disabled={disabled}
      {...props}
    >
      {content}
    </button>
  );
}
