/** @jsxImportSource @emotion/react */
import { ReactNode } from 'react';

import { PageId } from '../../consts';
import { pageGrid } from '../../styles';

type Props = { as?: string; children: ReactNode; id: PageId };

export default function Page({ as: Element = 'section', id, ...props }: Props) {
  return (
    <Element css={pageGrid.container} data-test-id={`page-${id}`} {...props} />
  );
}
