/** @jsxImportSource @emotion/react */
import { Fragment, lazy, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ModalSizeType } from '../../consts';
import { pageGrid } from '../../styles';
import { breaks } from '../../styles/tokens';
import useMediaQuery from '../../utils/useMediaQuery';
import CustomFreephone from '../CustomFreephone';
import { customFreephoneStyles } from '../CustomFreephone/styles';
import { Phone } from '../Icons';
import Img from '../Img';
import LinkButton, { IconAlignment } from '../LinkButton';
import Logo from '../Logo';
import PageRefresh from '../PageRefresh';
import PhoneNumberCta from '../PhoneNumberCta';
import * as styles from './styles';

const Modal = lazy(() => import('../Modal'));

export default function Header() {
  const isLargeScreen = useMediaQuery(breaks.midPageWidth);
  const [common] = useTranslation('common');
  const [config] = useTranslation('config');
  const homeUrl = config('urls.home');
  const promise = common('promise');
  const [isModalOpen, setModalOpen] = useState(false);
  const customFreephone = config('features.customFreephone');

  // Effect: Reset modal
  useEffect(() => {
    if (isLargeScreen) {
      setModalOpen(false);
    }
  }, [isLargeScreen]);

  const contact = useMemo(() => {
    return (
      <div
        css={
          customFreephone
            ? [styles.contact, customFreephoneStyles]
            : styles.contact
        }
      >
        {customFreephone ? (
          <CustomFreephone testId="phoneNumberCta-header" />
        ) : (
          <Fragment>
            <div css={styles.contactContent}>
              <p css={styles.freephone}>{common('freephone')}</p>
              <span css={styles.oneCallAway}>{common('oneCallAway')}</span>
              <span css={styles.phoneNumberCta}>
                <PhoneNumberCta testId="phoneNumberCta-header" />
              </span>
            </div>
          </Fragment>
        )}
        {promise && (
          <Img
            alt={promise.alt}
            css={styles.promise}
            src={promise.src}
            width={promise.width}
            height={promise.height}
          />
        )}
      </div>
    );
  }, [common, promise, customFreephone]);

  return (
    <header aria-label={`header`} css={[pageGrid.container, styles.header]}>
      <div css={styles.inner}>
        <h1 css={styles.logo}>
          <PageRefresh url={homeUrl}>
            <Logo />
          </PageRefresh>
        </h1>
        {isLargeScreen ? (
          contact
        ) : (
          <Fragment>
            <LinkButton
              icon={<Phone />}
              iconAlignment={IconAlignment.Left}
              iconSmall
              testId="open-modal-btn"
              onClick={() => setModalOpen(true)}
            >
              {common('oneCallAway')}
            </LinkButton>
            {isModalOpen && (
              <Modal
                onClose={() => setModalOpen(false)}
                size={ModalSizeType.Narrow}
              >
                {contact}
              </Modal>
            )}
          </Fragment>
        )}
      </div>
    </header>
  );
}
