import i18n from 'i18next';
import HttpApi from 'i18next-http-backend';
import { initReactI18next } from 'react-i18next';

import getConfig from '../getConfig';
import publicPath from '../publicPath';

const { defaultLocale, supportedLocales } = getConfig();

i18n
  .use(initReactI18next)
  .use(HttpApi)
  .init({
    // These names spaces should be preloaded as they contain critical content
    // * config - market specific config like features and URLs
    // * common - content that is used in many places
    // * errors - if the network fails, the error content will be read from cache
    ns: ['config', 'common', 'errors'],
    defaultNS: 'common',
    lng: defaultLocale,
    fallbackLng: false,
    supportedLngs: supportedLocales,
    load: 'currentOnly',
    lowerCaseLng: true,
    returnObjects: true,
    interpolation: {
      escapeValue: false,
      format: (value, format) => {
        if (format === 'lowercase') return value.toLowerCase();

        return value;
      },
    },
    backend: {
      loadPath: publicPath('/locales/{{lng}}/{{ns}}.json'),
    },
  });

export default i18n;
