import { useEffect, useMemo, useState } from 'react';

/**
 * React Hook that listens to a media query. Returning a boolean state.
 *
 * @param query - Accepts a media query string
 * @returns boolean state
 */
export default (query: string): boolean => {
  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
  const [state, setState] = useState(mediaQuery.matches);

  useEffect(() => {
    const handleChange = ({ matches }: MediaQueryListEvent) => {
      setState(matches);
    };

    // Safari does not support newer syntax
    // `mediaQuery.addEventListener('change', handleChange)`
    mediaQuery.addListener(handleChange);

    return () => {
      // Safari does not support newer syntax
      // `mediaQuery.removeEventListener('change', handleChange)`
      mediaQuery.removeListener(handleChange);
    };
  }, [mediaQuery]);

  return state;
};
