import { css } from '@emotion/react';

import { colors, spacing } from '../../styles/tokens';

export const skipLink = css`
  background-color: ${colors.white};
  height: 1px;
  left: -100vw;
  padding: ${spacing.xs} ${spacing.s};
  position: absolute;
  top: auto;
  width: 1px;

  &:focus {
    height: auto;
    left: 0;
    top: 0;
    width: auto;
  }
`;

export const content = css`
  min-height: 50vh;
`;
