/** @jsxImportSource @emotion/react */

import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { createBrowserHistory } from 'history';
import ReactDOM from 'react-dom';
import { Router } from 'react-router-dom';

import App from './components/App';
import Providers from './components/Providers';
import getAppInsightsKey from './utils/getAppInsightsKey';
import getConfig from './utils/getConfig';

const config = getConfig();

export default function boot() {
  const history = createBrowserHistory();
  const appInsightsKey = getAppInsightsKey();

  if (appInsightsKey) {
    // This could be provided via React context if we need it elsewhere
    const appInsights = new ApplicationInsights({
      config: { instrumentationKey: appInsightsKey },
    });

    appInsights.loadAppInsights();
    appInsights.trackPageView();

    history.listen(() => {
      setTimeout(() => {
        appInsights.trackPageView({ uri: window.location.pathname });
      }, 500);
    });
  }

  /* istanbul ignore next */
  if (
    process.env.NODE_ENV === 'development' &&
    process.env.REACT_APP_USE_MOCK === 'true'
  ) {
    // eslint-disable-next-line @typescript-eslint/no-var-requires, global-require
    const { worker } = require('./services/backend/mock');

    worker.start({ onUnhandledRequest: 'bypass' });
  }

  if ('console' in window) {
    // eslint-disable-next-line no-console
    console.log(
      `Stannah
    =======
    Build:  ${process.env.REACT_APP_BUILD_ID || 'N/A'}
    Mode:   ${
      process.env.NODE_ENV === 'development' ? 'Development' : 'Production'
    }
    Locale: ${config.defaultLocale}
    Market: ${config.marketId}`.replace(/^\s*/gm, '')
    );
  }

  ReactDOM.render(
    <Providers>
      <Router history={history}>
        <App />
      </Router>
    </Providers>,
    document.getElementById('app-root')
  );
}
