/* istanbul ignore file */

import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill/dist/abortcontroller-polyfill-only';

// IE11: Element.matches
// Ref: https://developer.mozilla.org/en-US/docs/Web/API/Element/matches#browser_compatibility
if (!Element.prototype.matches) {
  Element.prototype.matches =
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    Element.prototype.msMatchesSelector ||
    Element.prototype.webkitMatchesSelector;
}

// All files should provide an export
export {};
