import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { setTimeout } from 'timers';

import { Experiment } from '../../consts';
import { track } from '../tracking';

export function useGoogleOptimizeEffect() {
  useEffect(() => {
    // Push Google Optimise activation event to data layer
    track({ event: 'optimize.activate' });
  });
}

export function useExperimentValue(
  experiment = Experiment.Id,
  fallbackToExperiment = false
) {
  const [config] = useTranslation('config');
  const hasExperiment = config('features.hasExperiment');
  const [isExperiment, setIsExperiment] = useState(fallbackToExperiment);
  const experimentId = experiment;
  const interval = Experiment.Interval;
  const timeout = Experiment.Timeout;

  useEffect(() => {
    track({ event: 'optimize.activate' });

    const intervalId = setInterval(() => {
      if (hasExperiment) {
        if (window.google_optimize?.get(experimentId) !== undefined) {
          const variant = window.google_optimize.get(experimentId);

          setIsExperiment(variant === '1');
          clearInterval(intervalId);
        } else if (process.env.NODE_ENV === 'development') {
          clearInterval(intervalId);
        }
      } else {
        clearInterval(intervalId);
      }
    }, interval);

    const timeoutId = setTimeout(() => {
      clearInterval(intervalId);
    }, timeout);

    return () => {
      clearTimeout(timeoutId);
    };
  });
  return isExperiment;
}
