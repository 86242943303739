import { css } from '@emotion/react';

import { text } from '../../styles';
import { breaks, colors, spacing } from '../../styles/tokens';

const freephoneWrapperCommon = css`
  position: relative;
  display: flex;
  align-items: center;
  text-align: left;
  background: ${colors.white};
  border: 1px solid ${colors.grey300};
`;
const freephoneNumberCommon = css`
  ${text.size5};
  font-family: Arial;
  font-weight: bold;
  display: block;
  color: ${colors.customFreephoneGreen};
  text-decoration: none;
`;

const freephoneTextCommon = css`
  display: flex;
  align-items: center;
  height: calc(100% + ${spacing.xxs});
  background-color: ${colors.customFreephoneGreen};
  color: ${colors.white};
  font-family: Arial;
  font-style: normal;
  font-size: 12px;
  line-height: 13px;
  &:before {
    content: '';
    display: inline-block;
    position: absolute;
    border-style: solid;
  }
`;

const freephoneWidthBoxed = css`
  width: 160px;
`;

export const customFreephoneStyles = css`
  @media ${breaks.midPageWidth} {
    grid-auto-flow: row;
  }
  span {
    display: block;
    width: 100%;
  }
`;
export const freephoneWrapper = css`
  ${freephoneWrapperCommon};
  flex-direction: row;
  width: 275px;
  height: 34px;
  padding-left: ${spacing.xs};
  margin-left: auto;
  margin-right: auto;
`;

export const freephoneWrapperBoxed = css`
  ${freephoneWrapperCommon};
  ${freephoneWidthBoxed};
  flex-direction: column;
  height: auto;
  padding: 0;
  margin-left: auto;
  margin-right: auto;
`;

export const freephoneWrapperSpacing = css`
  margin-top: ${spacing.xs};
`;

export const freephoneWrapperCenteredMd = css`
  @media ${breaks.midPageWidth} {
    margin-left: 0;
    margin-right: 0;
  }
`;
export const freephoneWrapperCenteredLg = css`
  @media ${breaks.midPageWidth} {
    margin-left: auto;
    margin-right: auto;
  }
`;
export const freephoneNumber = css`
  a {
    ${freephoneNumberCommon};
    width: 140px;
  }
`;

export const freephoneNumberBoxed = css`
  ${freephoneWidthBoxed};
  padding-left: calc(${spacing.xs} + 5px);
  padding-right: ${spacing.xs};
  a {
    ${freephoneNumberCommon};
  }
`;

export const freephoneText = css`
  ${freephoneTextCommon};
  position: absolute;
  top: -(${spacing.xxs} / 2);
  right: ${spacing.xxs};
  width: 120px;
  padding: ${spacing.xxs} ${spacing.xs} ${spacing.xxs} ${spacing.s};
  &:before {
    left: 0;
    width: 0;
    height: 0;
    border-width: 10px 0 10px 10px;
    border-color: transparent transparent transparent ${colors.white};
  }
`;

export const freephoneTextBoxed = css`
  ${freephoneTextCommon};
  ${freephoneWidthBoxed};
  position: relative;
  top: 0;
  right: 0;
  padding: ${spacing.xxs} ${spacing.m} ${spacing.xxs} ${spacing.l};
  &:before {
    top: 0;
    left: 10px;
    height: 0;
    border-width: 10px 10px 0 10px;
    border-color: ${colors.white} transparent transparent transparent;
  }
`;
