/** @jsxImportSource @emotion/react */
import { AnchorHTMLAttributes, ReactNode } from 'react';

import useInfinityTracking from '../../utils/useInfinityTracking';
import useMatelsoTracking from '../../utils/useMatelsoTracking';
import ExternalLink from '../ExternalLink';

type Props = { children: ReactNode } & AnchorHTMLAttributes<HTMLAnchorElement>;
export default function Link({ children, href }: Props) {
  useInfinityTracking();

  useMatelsoTracking();

  if (href?.startsWith('http')) {
    return <ExternalLink href={href}>{children}</ExternalLink>;
  }
  if (href?.startsWith('tel:')) {
    return (
      <a className="InfinityNumber" href={href}>
        {children}
      </a>
    );
  }
  return <a href={href}>{children}</a>;
}
