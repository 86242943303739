import { ReactNode } from 'react';
import { Link, useHistory } from 'react-router-dom';

type Props = {
  url: string;
  children: ReactNode;
};

export default function PageRefresh({ url, children }: Props) {
  const history = useHistory();

  const pageRefreshHandler = () => {
    history.push(url);
    history.go(0);
  };

  return (
    <Link to={url} onClick={pageRefreshHandler}>
      {children}
    </Link>
  );
}
